//==============================================================================
// Scroll Event Manager
//
// Applies classes dynamically, based on scroll position
//==============================================================================
import * as React from 'react';
import throttle from 'lodash/throttle';

import { IDictionary } from '@msdyn365-commerce/core';

import { IScrollEventManagerData } from './scroll-event-manager.data';
import { EventsAction, EventsPosition, IEventsData, IScrollEventManagerProps } from './scroll-event-manager.props.autogenerated';

//==============================================================================
// INTERFACES AND CONSTANTS
//==============================================================================
interface Action {
    target: HTMLCollectionOf<Element>;
    action: string;
    className: string;
}

interface Extents {
    top: number;
    bottom: number;
}

// Only vaguely useful enum -- prevents typos and provides a thin isolation layer
const enum ElementPositions {
    above = 'above',
    below = 'below',
    visible = 'visible',
    missing = 'missing',
}

//==============================================================================
// CLASS DEFINITION
//==============================================================================
/**
 * ScrollEventManager component
 * @extends {React.PureComponent<IScrollEventManagerProps<IScrollEventManagerData>>}
 */
//==============================================================================
class ScrollEventManager extends React.PureComponent<IScrollEventManagerProps<IScrollEventManagerData>> {

    // Current triggered state
    private eventState: boolean[] = [];

    // Cached list of elements that are the trigger or target of an event
    private elements: IDictionary<HTMLCollectionOf<Element>> = {};

    // Throttled event handler
    private throttledScrollhandler: () => void;

    // Copy of the event list
    private eventList: IEventsData[] = [];

    //==========================================================================
    // PUBLIC METHODS
    //==========================================================================

    //------------------------------------------------------
    // Constructor
    //------------------------------------------------------
    constructor(props: IScrollEventManagerProps<IScrollEventManagerData>) {
        super(props);

        // Throttle the event handler to prevent event overloading
        this.throttledScrollhandler = throttle(this._scrollHandler.bind(this), 50);

        // Copy the supplied event list, adding any extras requested
        this._createEventList(this.props.config.events || []);
    }

    //------------------------------------------------------
    // Attach event handler
    //------------------------------------------------------
    public componentDidMount(): void {
        if (process.env.CURRENT_ENVIRONMENT === 'web') {
            window && window.addEventListener && window.addEventListener('scroll', this.throttledScrollhandler);

            // Trigger any initial events
            this._scrollHandler();
        }
    }

    //------------------------------------------------------
    // Detach event handler
    //------------------------------------------------------
    public componentWillUnmount(): void {
        window && window.removeEventListener && window.removeEventListener('scroll', this.throttledScrollhandler);
    }

    //------------------------------------------------------
    // Required, but doesn't do anything
    //------------------------------------------------------
    public render(): null {
        return null;
    }

    //==========================================================================
    // PRIVATE METHODS
    //==========================================================================

    //------------------------------------------------------
    // Copy the supplied event list, generating extra events
    // for all events flagged as bidirectional.
    //------------------------------------------------------
    private _createEventList(events: IEventsData[]): void {
        const eventOpposites = {
            onScreen: EventsPosition.offScreen,
            onScreenAbove: EventsPosition.offScreenBelow,
            onScreenBelow: EventsPosition.offScreenAbove,
            offScreen: EventsPosition.onScreen,
            offScreenAbove: EventsPosition.onScreenBelow,
            offScreenBelow: EventsPosition.onScreenAbove
        };

        // Step through the event list
        events.forEach(event => {

            // Add the primary event
            this.eventList.push(event);

            // Check to see if we need an opposite event
            if (event.bidirectional) {
                const cloned = { ...event };

                // Flip the action
                cloned.action = (event.action === EventsAction.add) ? EventsAction.remove : EventsAction.add;

                // Flip the trigger type
                cloned.position = eventOpposites[event.position];

                // Add the cloned event
                this.eventList.push(cloned);
            }
        });
    }

    //------------------------------------------------------
    // Main top-level functionality: Watch for scroll
    // events, and trigger the appropriate actions when
    // necessary.
    //------------------------------------------------------
    private _scrollHandler(): void {
        const events = this.eventList;

        if (!events || !events.length) {
            return;
        }

        // Ensure elements have been cached
        this._getElements(events);

        // Create a running list of actions, with redundant ones removed.
        // Some will supercede others, so keep a list instead of acting immediately.
        const actionList: IDictionary<Action> = {};

        // Step through the event list
        events.forEach((event, index) => {

            // Get the position of the trigger element
            const position = this._getPosition(event.triggerClass, event.offset || 0);

            // Ensure required elements exist in the DOM, then
            // determine if the new position matches the event trigger
            if ((position !== ElementPositions.missing) && this._isEventTriggered(position, event.position, index)) {

                // Add the requested action to our list,
                // replacing any other actions that may now be redundant
                this._addAction(actionList, event);
            }
        });

        // Perform any actions
        this._performActions(actionList);
    }

    //------------------------------------------------------
    // Looks for any classes that we haven't tied to
    // elements yet.
    //------------------------------------------------------
    private _getElements(events: IEventsData[]): void {
        if ((typeof document !== 'undefined') && events && events.length) {

            // Each event entry has two separate selectors (there are 3 classes, but one isn't used as a selector)
            events.forEach(entry => {
                this._addElement(entry.triggerClass);   // This needs to match a single element only (more matches will be ignored)
                this._addElement(entry.targetClass);    // This can match multiple elements
            });
        }
    }

    //------------------------------------------------------
    // For a single className, lookup and cache the element
    // if we haven't already done so.
    //------------------------------------------------------
    private _addElement(selector: string): void {

        // Check if the element has been located yet
        if (selector && !this.elements[selector]) {
            // Still unknown -- try to find it now
            this.elements[selector] = document.getElementsByClassName(selector);
        }
    }

    //------------------------------------------------------
    // Returns the current position of the selected element,
    // relative to the screen.
    //
    // NOTE: Returning an enum would be better TypeScript,
    // but the value is used with objects, which don't play
    // nice with enums.
    //------------------------------------------------------
    private _getPosition(selector: string, offset: number): string {
        if (!selector || !this.elements[selector] || !this.elements[selector].length) {
            return ElementPositions.missing;    // Arbitrary value -- none are correct if elements haven't been loaded yet
        }

        // Get the element's top and bottom position
        const elementExtents = this._getElementExtents(this.elements[selector][0], offset); // There could be more than one match of the trigger element, but we're ignoring extras

        // Get the window's top and bottom position
        const windowExtents = this._getWindowExtents();

        // Compare them for a result
        if (elementExtents.bottom < windowExtents.top) {
            return ElementPositions.above;
        }

        if (elementExtents.top > windowExtents.bottom) {
            return ElementPositions.below;
        }

        return ElementPositions.visible;
    }

    //------------------------------------------------------
    // Checks a single event definition to see if it has
    // been newly triggered.
    //------------------------------------------------------
    private _isEventTriggered(currentPosition: string, targetPosition: string, index: number): boolean {

        // Converts between three-state position and Target Position values
        const positionMap: IDictionary<IDictionary<boolean>> = {
            onScreen: {
                visible: true,
            },
            onScreenAbove: {
                visible: true,
                above: true,
            },
            onScreenBelow: {
                visible: true,
                below: true,
            },
            offScreen: {
                above: true,
                below: true,
            },
            offScreenAbove: {
                above: true,
            },
            offScreenBelow: {
                below: true,
            },
        };

        const isTriggered: boolean = positionMap[targetPosition][currentPosition];

        // Check for a state change
        if (this.eventState[index] !== isTriggered) {

            // Update the stored state
            this.eventState[index] = isTriggered;

            // Determine if we should notify the caller of the change.
            // If we just transitioned into a triggered state, notify (return true).
            // If we just transitioned into a non-triggered state, keep quiet.
            return isTriggered;
        }

        return false;
    }

    //------------------------------------------------------
    // Add an action to our running action list.
    // Overwrites redundant actions.
    //------------------------------------------------------
    private _addAction(actionList: IDictionary<Action>, event: IEventsData): void {
        if (event.targetClass && event.actionClass &&
            this.elements[event.targetClass] && this.elements[event.targetClass].length) {

            // Unique actions consist of both a selector and class
            const eventIdentifier = `${event.targetClass}-${event.actionClass}`;

            // Store the event, possibly overwriting other redundant events
            actionList[eventIdentifier] = {
                target: this.elements[event.targetClass],   // There could be more than one, which is valid here.
                action: event.action,
                className: event.actionClass
            };
        }
    }

    // -----------------------------------------------------
    // Process all of the actions in our action list.
    // -----------------------------------------------------
    private _performActions(actionList: IDictionary<Action>): void {

        // Step through the action list
        Object.values(actionList).forEach(action => {

            // Determine the appropriate function
            const actionFunction = (action.action === EventsAction.add) ? 'add' : 'remove';

            // Allow multiple classes to be added or removed at the same time
            const classList = action.className.split(',').map(entry => entry.trim());

            // Peform the action
            for (let index = 0; index < action.target.length; index++) {
                action.target[index].classList[actionFunction](...classList);
            }
        });
    }

    //------------------------------------------------------
    // Determine the top and bottom coordinates of the
    // viewport.
    //------------------------------------------------------
    private _getWindowExtents(): Extents {
        const topOffset: number = this.props.config.topOffset || 0;
        const bottomOffset: number = this.props.config.bottomOffset || 0;

        // Get the current scroll position
        // @ts-ignore
        const scrollPos: number = (window.pageYOffset !== undefined) ? window.pageYOffset : window.scrollTop;

        // Get the viewport height
        const viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

        return {
            top: scrollPos + topOffset,
            bottom: scrollPos + viewportHeight - bottomOffset,
        };
    }

    //------------------------------------------------------
    // Determine the top and bottom coordinates of the
    // viewport.
    //
    // We'll use the offset to shift the element's location.
    // Logically, it would probably be better to process the
    // offset during the viewport and element comparison,
    // but it's cleaner and simpler here.
    //------------------------------------------------------
    private _getElementExtents(element: Element, offset: number): Extents {
        const top = this._getElementTop(element);

        return {
            top: top + offset,
            bottom: top + element.clientHeight + offset,
        };
    }

    //------------------------------------------------------
    // Find the top coordinate of an element, in relation to
    // the page.
    //
    // Adapted from react-animate-on-scroll by dbramwell.
    //------------------------------------------------------
    private _getElementTop(element: Element): number {
        let yPos = 0;
        let elm: (HTMLElement | null) = element as HTMLElement;

        while (elm && elm.offsetTop !== undefined && elm.clientTop !== undefined) {
            yPos += (elm.offsetTop + elm.clientTop);
            elm = elm.offsetParent as HTMLElement;
        }

        return yPos;
    }
}

export default ScrollEventManager;
